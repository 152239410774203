{
    "useLongScale": false,
    "baseSeparator": " ",
    "unitSeparator": "",
    "base": {
        "0": "ноль",
        "1": "один",
        "2": "два",
        "3": "три",
        "4": "четыре",
        "5": "пять",
        "6": "шесть",
        "7": "семь",
        "8": "восемь",
        "9": "девять",
        "10": "десять",
        "11": "одинадцать",
        "12": "двенадцать",
        "13": "тринадцать",
        "14": "четырнадцать",
        "15": "пятнадцать",
        "16": "шестнадцать",
        "17": "семнадцать",
        "18": "восемнадцать",
        "19": "девятнадцать",
        "20": "двадцать",
        "30": "тридцать",
        "40": "сорок",
        "50": "пятьдесят",
        "60": "шестьдесят",
        "70": "семьдесят",
        "80": "восемьдесят",
        "90": "девяносто",
        "100": "сто",
        "200": "двести",
        "300": "триста",
        "400": "четыреста",
        "500": "пятьсот",
        "600": "шестьсот",
        "700": "семьсот",
        "800": "восемьсот",
        "900": "девятьсот"
    },
    "alternativeBase": {
        "feminine": {
            "1": "одна",
            "2": "две"
        }
    },
    "units": [
        {
            "useBaseInstead": true,
            "useBaseException": []
        },
        {
            "singular": "тысяча",
            "few": "тысячи",
            "plural": "тысяч",
            "useAlternativeBase": "feminine",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "миллион",
            "few": "миллиона",
            "plural": "миллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "миллиард",
            "few": "миллиарда",
            "plural": "миллиардов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "триллион",
            "few": "триллиона",
            "plural": "триллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "квадрильон",
            "few": "квадриллион",
            "plural": "квадрилон",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "квинтиллион",
            "few": "квинтиллиона",
            "plural": "квинтильонов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "секстиллионов",
            "few": "секстильона",
            "plural": "секстиллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "септиллион",
            "few": "септиллиона",
            "plural": "септиллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "октиллион",
            "few": "октиллиона",
            "plural": "октиллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "нониллион",
            "few": "нониллиона",
            "plural": "нониллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "дециллион",
            "few": "дециллиона",
            "plural": "дециллионов",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "ундециллион",
            "few": "ундециллиона",
            "plural": "ундециллионив",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "дуодециллион",
            "few": "дуодециллиона",
            "plural": "дуодециллионив",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "тредециллион",
            "few": "тредециллиона",
            "plural": "тредециллионив",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "кватуордециллион",
            "few": "кватуордециллиона",
            "plural": "кватуордециллионив",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        },
        {
            "singular": "квиндециллион",
            "few": "квиндециллиона",
            "plural": "квиндециллионив",
            "useSingularEnding": true,
            "useFewEnding": true,
            "avoidEndingRules": [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214, 311, 312, 313, 314, 411, 412, 413, 414, 511, 512, 513, 514, 611, 612, 613, 614, 711, 712, 713, 714, 811, 812, 813, 814, 911, 912, 913, 914]
        }
    ],
    "unitExceptions": []
}