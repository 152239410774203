export const locale = {
    lang: 'pt',
    data: {
        NUMBER_HELPER: {
            WRITTEN_NUMBER: {
                OPERATOR: 'e',
                ARS: {
                    INTEGER: {
                        ONE: 'peso',
                        OTHER: 'pesos',
                    },
                    DECIMAL: {
                        ONE: 'centavo',
                        OTHER: 'centavos',
                    },
                },
                BRL: {
                    INTEGER: {
                        ONE: 'real',
                        OTHER: 'reais',
                    },
                    DECIMAL: {
                        ONE: 'centavo',
                        OTHER: 'centavos',
                    },
                },
                EUR: {
                    INTEGER: {
                        ONE: 'euro',
                        OTHER: 'euros',
                    },
                    DECIMAL: {
                        ONE: 'centavo',
                        OTHER: 'centavos',
                    },
                },
                USD: {
                    INTEGER: {
                        ONE: 'dólar',
                        OTHER: 'dólares',
                    },
                    DECIMAL: {
                        ONE: 'centavo',
                        OTHER: 'centavos',
                    },
                },
            },
        },
    },
};
