import { Injector, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'app/core/helpers/util';
import { EAuthenticationStorage } from 'app/core/models/authentication/authentication';
import { MoneyService } from 'app/core/services/money/money.service';
import { FuseTranslationLoaderService } from 'app/core/translate/translation-loader.service';
import { first } from 'rxjs';
import writternNumber from 'written-number';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';

export const translateNumberHelper = signal<any>({});

export const populateTranslateNumberHelper = (fuseTranslationLoaderService: FuseTranslationLoaderService, translateService: TranslateService) => {
    fuseTranslationLoaderService.loadTranslations(portuguese, english, spanish);
    translateService
        .get('NUMBER_HELPER')
        .pipe(first())
        .subscribe((response: any) => {
            translateNumberHelper.set(response);
        });
};

interface IWrittenNumberCurrency {
    value: number | string;
    lang?: string;
    currencyIsoCode?: string;
    injector?: Injector;
}

export class NumberHelper {
    static convertNumberDecimalCase(value: number, quantityDecimalCase: number): number {
        return Number(value.toFixed(quantityDecimalCase));
    }

    static convertToApi(value: number | string): number {
        if (typeof value === 'string') {
            value = Number(value);
        }

        return Math.round(Number(value.toFixed(2)) * 100);
    }

    static writtenNumberCurrency(data: IWrittenNumberCurrency): string {
        const moneyService = data?.injector?.get(MoneyService) ?? inject(MoneyService);
        const {
            value,
            lang = localStorage.getItem(EAuthenticationStorage.LANG) ?? 'pt',
            currencyIsoCode = moneyService.maskMoney.isoCode,
        } = data;

        const currentValue = Number(Number(value).toFixed(2));
        const decimal = Math.floor((currentValue % 1) * 100);
        const integer = Number(currentValue) - decimal;

        let decimalWord = writternNumber(decimal, { lang });
        const integerWord = writternNumber(integer, { lang });

        const operator = Util.translateKey(translateNumberHelper(), 'WRITTEN_NUMBER.OPERATOR', {});
        const textInterger = Util.translateKey(
            translateNumberHelper(),
            `WRITTEN_NUMBER.${currencyIsoCode}.INTEGER`,
            {},
            integer <= 1 ? 'one' : 'other',
        );
        const textDecimal = Util.translateKey(
            translateNumberHelper(),
            `WRITTEN_NUMBER.${currencyIsoCode}.DECIMAL`,
            {},
            decimal <= 1 ? 'one' : 'other',
        );

        let result = `${integerWord} ${textInterger}`;

        if (decimal !== 0) {
            result += ` ${operator} ${decimalWord} ${textDecimal}`;
        }

        return result.trim();
    }
}
