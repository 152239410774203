export const locale = {
    lang: 'en',
    data: {
        NUMBER_HELPER: {
            WRITTEN_NUMBER: {
                OPERATOR: 'and',
                ARS: {
                    INTEGER: {
                        ONE: 'peso',
                        OTHER: 'pesos',
                    },
                    DECIMAL: {
                        ONE: 'cent',
                        OTHER: 'cents',
                    },
                },
                BRL: {
                    INTEGER: {
                        ONE: 'real',
                        OTHER: 'reais',
                    },
                    DECIMAL: {
                        ONE: 'cent',
                        OTHER: 'cents',
                    },
                },
                EUR: {
                    INTEGER: {
                        ONE: 'euro',
                        OTHER: 'euros',
                    },
                    DECIMAL: {
                        ONE: 'cent',
                        OTHER: 'cents',
                    },
                },
                USD: {
                    INTEGER: {
                        ONE: 'dollar',
                        OTHER: 'dollars',
                    },
                    DECIMAL: {
                        ONE: 'cent',
                        OTHER: 'cents',
                    },
                },
            },
        },
    },
};
